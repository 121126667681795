import urls from '@/constants/endpoints/gateway';
import observationsUrls from '@/constants/endpoints/observations';
import {
    BuildInfo,
    CancellationRequestStatus,
    ClusterResponseDTO,
    Ethnicities,
    GenderIdentity,
    HandOffStatus,
    HandOffUrgency,
    InstancePropsResponseDto,
    LanguageDTO,
    MessageType,
    MissionControlResponseDTO,
    NurseOption,
    PodDTO,
    Races,
    Role,
    RoutingProfileDTO,
    StateResponseDTO,
    TimeZoneDTO,
    Town,
} from '@/types/gatewayDataModels';
import {ActivityRefuseReasonDTO, GetThresholdType, ThresholdTypesParams} from '@/types/observationsModels';

import httpApi from './httpApi_new';

class GeneralInfoService {
    getSponsors = (): Promise<string[]> => httpApi.get({url: urls.SPONSORS});
    getSponsorsDetailedList = (): Promise<InstancePropsResponseDto[]> =>
        httpApi.get({url: urls.SPONSORS_DETAILED_LIST});
    getPods = (): Promise<PodDTO[]> => httpApi.get({url: urls.PODS});
    getProducts = (): Promise<string[]> => httpApi.get({url: urls.PRODUCTS});
    getPayors = (): Promise<string[]> => httpApi.get({url: urls.PAYORS});
    getIsoLanguages = (): Promise<LanguageDTO[]> => httpApi.get({url: urls.ISO_LANGUAGES});
    getGenders = (): Promise<string[]> => httpApi.get({url: urls.GENDERS});
    getGenderIdentities = (): Promise<GenderIdentity[]> => httpApi.get({url: urls.GENDER_IDENTITIES});
    getTimeZones = (): Promise<TimeZoneDTO[]> => httpApi.get({url: urls.TIME_ZONES});
    getStates = (): Promise<StateResponseDTO[]> => httpApi.get({url: urls.GEOGRAPHY_STATES});
    getMissionControls = (): Promise<MissionControlResponseDTO[]> => httpApi.get({url: urls.MISSION_CONTROLS});
    getClusters = (): Promise<ClusterResponseDTO[]> => httpApi.get({url: urls.CLUSTERS});
    getNurseRoles = (): Promise<Role[]> => httpApi.get({url: urls.PERSONNEL_ROLES});
    getNurseProfiles = () =>
        httpApi.get<RoutingProfileDTO[]>({url: urls.ROUTING_PROFILES, origin: window.REACT_APP_CORE_API_URL});

    getThresholdTypes = (queryParams?: ThresholdTypesParams): Promise<GetThresholdType[]> =>
        httpApi.get({url: observationsUrls.ALARMS_THRESHOLD_TYPES, queryParams});

    getIncidentsStatuses = (): Promise<('OPEN' | 'FINISHED' | 'ACKNOWLEDGED' | 'CLOSED')[]> =>
        httpApi.get({url: observationsUrls.ALARMS_STATUS_FILTER_VALUES});

    getLocal911Types = (): Promise<string[]> => httpApi.get({url: urls.LOCAL_911_TYPES});
    getAdditionalLocalEmergencyTypes = (): Promise<string[]> =>
        httpApi.get({url: urls.ADDITIONAL_LOCAL_EMERGENCY_TYPES});

    getHandOffStatuses = (): Promise<HandOffStatus[]> => httpApi.get({url: urls.HAND_OFF_STATUSES});
    getHandOffUrgencies = (): Promise<HandOffUrgency[]> => httpApi.get({url: urls.HAND_OFF_URGENCIES});

    getHandOffCancellationStatuses = (): Promise<CancellationRequestStatus[]> =>
        httpApi.get({url: urls.HAND_OFF_CANCELLATION_STATUSES});
    getHandOffCancellationUrgencies = (): Promise<HandOffUrgency[]> =>
        httpApi.get({url: urls.HAND_OFF_CANCELLATION_URGENCIES});

    getCities = (state: string): Promise<Town[]> => httpApi.get({url: urls.GEOGRAPHY_CITIES, queryParams: {state}});

    getEhrs = (): Promise<string[]> => httpApi.get({url: urls.EHRS});
    getRelations = (): Promise<string[]> => httpApi.get({url: urls.RELATIONS});
    getMessageTypes = (): Promise<MessageType[]> => httpApi.get({url: urls.MESSAGE_TYPES});
    getPhaseOfCare = (): Promise<string[]> => httpApi.get({url: urls.PHASE_OF_CARE});

    getNurseOptions = (): Promise<NurseOption[]> => httpApi.get({url: urls.NURSE_OPTIONS});

    getGeneralAppointmentTypes = (): Promise<string[]> => httpApi.get({url: urls.GENERAL_APPOINTMENTS_TYPES});

    getSelfCheckInOptions = (): Promise<{[key: string]: string}> => httpApi.get({url: urls.SELF_CHECK_IN_OPTIONS});
    getSelfCheckOutOptions = (): Promise<{[key: string]: string}> => httpApi.get({url: urls.SELF_CHECK_OUT_OPTIONS});

    getActivityRefuseReasonsOptions = (): Promise<ActivityRefuseReasonDTO[]> =>
        httpApi.get({url: urls.ACTIVITY_REFUSE_REASONS});
    getSexualOrientations = (): Promise<string[]> => httpApi.get({url: urls.SEXUAL_ORIENTATIONS});
    getRaces = () => httpApi.get<Races[]>({url: urls.RACES});
    getEthnicities = () => httpApi.get<Ethnicities[]>({url: urls.ETHNICITIES});

    getBuildInfo = (): Promise<BuildInfo> => httpApi.get({url: urls.BUILD_INFO});
}

const generalInfoService = new GeneralInfoService();
export default generalInfoService;
