import {toast} from 'react-toastify';

import {ToastMessage} from '@/components/general/ToastMessage';

type Status = 'info' | 'success' | 'warning' | 'error' | 'default';
type NotifyOptions = {
    message: string;
    status: Status;
    title?: string;
    showInBackground?: boolean;
    dismissAfter?: number;
};

const generalNotify = ({
    showInBackground = false,
    title = '',
    message = '',
    status = 'info',
    dismissAfter = 3000,
}: NotifyOptions) => {
    toast(<ToastMessage type={status} title={title} message={message} />, {
        type: status,
        icon: false,
        autoClose: dismissAfter,
    });

    showInBackground && browserNotify(title, message);
};

// this is mostly used with general notify but can be additionally added to custom notifications f.e aws/zoom calls
const browserNotify = (title: string, message: string) => {
    if (!document.hidden) return;

    if (Notification.permission === 'granted') {
        new Notification(title, {body: message});
    } else if (Notification.permission !== 'denied') {
        try {
            Notification.requestPermission().then((result) => {
                if (result === 'granted') new Notification(title, {body: message});
                console.log(`notification permission: ${result}`);
            });
        } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it
            // throws a TypeError. It takes a callback as the first argument
            // instead.
            if (error instanceof TypeError) {
                Notification.requestPermission(() => {
                    console.log(`notification permission -`);
                });
            } else {
                throw error;
            }
        }
    } else {
        console.log('notification permission denied');
    }
};

export {browserNotify, generalNotify};
