import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CommonData} from '@/types/commonData';

import {CommonDataState} from './stateTypes';

const initialData: CommonData = {
    payors: [],
    sponsors: [],
    pods: [],
    products: [],
    timeZones: [],
    states: [],
    missionControls: [],
    nurseOptions: [],
    measurementUnits: [],
    deviceModels: [],
    devicesStatuses: [],
    deviceFields: {},
    kitSpecifications: [],
    kitHomeLocations: [],
    kitLocations: [],
    kitStatuses: [],
    thresholdTypes: [],
    incidentsStatuses: [],
    isoLanguages: [],
    genders: [],
    genderIdentities: [],
    orderNames: [],
    orderFrequencies: [],
    orderTypes: [],
    taskCategories: [],
    contactTypes: [],
    kitAssignmentStatuses: [],
    kitLocationsAll: [],
    recentMetricTypes: [],
    orderIgnoreReasons: [],
    appointmentCancelReasons: [],
    serviceRequestCancelReasons: [],
    clusters: [],
    nurseProfiles: [],
    nurseRoles: [],
    local911Types: [],
    additionalLocalEmergencyTypes: [],
    handOffStatuses: [],
    handOffUrgencies: [],
    handOffCancellationStatuses: [],
    handOffCancellationUrgencies: [],
    ehrs: [],
    relations: [],
    messageTypes: [],
    phaseOfCare: [],
    vendorSubstatuses: [],
    generalAppointmentsTypes: [],
    uiManagedSettings: [],
    selfCheckInOptions: {},
    selfCheckOutOptions: {},
    activityRefuseReasons: [],
    arrivalWindowTimeSlots: [],
    appointmentVisitTypes: [],
    improvementLogEventTypes: [],
    improvementLogRootCauses: {},
    improvementLogResponsibleActors: [],
    improvementLogLevelsOfInvestigation: [],
    serviceProviders: [],
    sexualOrientations: [],
    races: [],
    ethnicities: [],
    submissionTypes: [],
};
const initialLoadings = Object.keys(initialData).reduce<{
    [key: string]: boolean;
}>((acc, name) => {
    acc[`${name}Loading`] = true;
    return acc;
}, {});

const initialState: CommonDataState = {...initialData, ...initialLoadings};

const SLICE_NAME = 'commonDataReducer';
const {actions, reducer} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        getCommonData: (state, _action: PayloadAction<(keyof CommonData)[]>) => state,
        getCommonDataStart: (state, action: PayloadAction<(keyof CommonData)[]>) => {
            action.payload.forEach((key) => {
                state[`${key}Loading`] = true;
            });
        },
        getCommonDataSuccess: (
            state,
            action: PayloadAction<{
                keys: (keyof CommonData)[];
                data: any;
            } | null>
        ) => {
            if (!action.payload) return;

            const {keys, data} = action.payload;
            keys.forEach((key) => {
                state[`${key}Loading`] = false;
                state[key] = data[key];
            });
        },
        getCommonDataFail: (state, action: PayloadAction<(keyof CommonData)[]>) => {
            action.payload.forEach((key) => {
                state[`${key}Loading`] = false;
            });
        },
    },
});

export const {getCommonData, getCommonDataSuccess, getCommonDataFail, getCommonDataStart} = actions;

export default reducer;
