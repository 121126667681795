import urls from '@/constants/endpoints/gateway';
import {
    CreatedResponseDTO,
    CreateEquipmentKitRequestDTO,
    CylinderDurationRequestDTO,
    CylinderDurationResponseDTO,
    DeviceResponseDTO,
    DeviceUpdateResponseDTO,
    EquipmentDepotResponseDTO,
    EquipmentKitHistoryEntryResponseDTO,
    EquipmentKitResponseDTO,
    EquipmentKitSpecification,
    EquipmentKitStatus,
    EquipmentKitStatusChangeResponseDTO,
    EquipmentKitWithDependenciesResponseDTO,
    IdsRequestParamDTO,
    NonKitDeviceRequestDTO,
    NonKitDeviceResponseDTO,
    NonKitDeviceSpecification,
    PatientCalculateEcylinderDurationRequestDTO,
    PostInstallationCheckListResponseDTO,
    RemoveDeviceFromEquipmentKitRequestDTO,
    SimpleMessageResponseDTO,
    VisualDocumentationResponseDTO,
    WifiNetworkResponse,
} from '@/types/gatewayDataModels';

import httpApi from './httpApi_new';

type KitAssignmentStatus = 'ASSIGNED' | 'NOT_ASSIGNED' | 'ANY';

class KitService {
    getNonKitDevicesSpecifications = (): Promise<NonKitDeviceSpecification[]> =>
        httpApi.get({url: urls.EQUIPMENT_NON_KIT_DEVICES_SPECIFICATIONS});
    getKitSpecifications = (): Promise<EquipmentKitSpecification[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_SPECIFICATIONS});
    getKitHomeLocations = (): Promise<EquipmentDepotResponseDTO[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_LOCATIONS, queryParams: {types: 'HOME_LOCATION'}});
    getKitLocations = (): Promise<EquipmentKitResponseDTO[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_LOCATIONS, queryParams: {types: 'NOT_SPECIFIED'}});
    getKitStatuses = (): Promise<EquipmentKitStatus[]> => httpApi.get({url: urls.EQUIPMENT_KIT_STATUSES});
    getKitNameExists = (kitName: string): Promise<boolean> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_NAME_EXISTS, queryParams: {kitName}});
    getKitDevices = (kitId: number): Promise<DeviceResponseDTO[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_DEVICES(kitId)});
    getKitAssignmentStatuses = (): Promise<KitAssignmentStatus[]> => httpApi.get({url: urls.KIT_ASSIGNMENT_STATUSES});
    getKitAllLocations = (): Promise<EquipmentDepotResponseDTO[]> => httpApi.get({url: urls.EQUIPMENT_KIT_LOCATIONS});

    getKits = ({
        sorting,
        sortingOrder,
        searchText,
        includeDemo,
        location,
        kitType,
        status,
    }: {
        sorting: string;
        sortingOrder: 'desc' | '';
        searchText: string;
        includeDemo: boolean;
        location: number;
        kitType: string;
        status: EquipmentKitStatus;
    }): Promise<EquipmentKitWithDependenciesResponseDTO[]> => {
        const queryParams = {
            include_demo: includeDemo,
            ...(searchText ? {name: searchText} : {}),
            ...(location ? {home_locations_ids: location} : {}),
            ...(kitType ? {kit_type: kitType} : {}),
            ...(status ? {statuses: status} : {}),
            sort: `${sorting}${sortingOrder ? `,${sortingOrder}` : ''}`,
        };
        return httpApi.get({
            url: urls.EQUIPMENT_KITS,
            queryParams,
        });
    };

    getKit = (kitId: number): Promise<EquipmentKitWithDependenciesResponseDTO> =>
        httpApi.get({url: urls.EQUIPMENT_KIT(kitId)});
    createKit = (data: CreateEquipmentKitRequestDTO): Promise<CreatedResponseDTO> =>
        httpApi.post({url: urls.EQUIPMENT_KITS, data});
    updateKit = ({
        kitId,
        data,
    }: {
        kitId: number;
        data: CreateEquipmentKitRequestDTO;
    }): Promise<EquipmentKitResponseDTO> => httpApi.put({url: urls.EQUIPMENT_KIT(kitId), data});

    getPatientKits = (id: number): Promise<EquipmentKitResponseDTO[]> => httpApi.get({url: urls.PATIENT_INVENTORY(id)});

    getUnassignedKits = ({
        missionControlId,
        demoPatient,
    }: {
        missionControlId: number;
        demoPatient: boolean;
    }): Promise<EquipmentKitWithDependenciesResponseDTO[]> => {
        return httpApi.get({
            url: urls.EQUIPMENT_KITS,
            queryParams: {
                statuses: ['READY_FOR_USE'],
                is_assigned: false,
                include_demo: demoPatient,
                patientMissionControlId: +missionControlId,
            },
        });
    };

    assignKitToPatient = ({id, kits}: {id: number; kits: number[]}): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({url: urls.PATIENT_INVENTORY(id), data: {ids: kits}});

    assignDevicesToKit = ({kitId, deviceId}: {kitId: number; deviceId: number}): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({url: urls.EQUIPMENT_KIT_DEVICES(kitId), data: {ids: [deviceId]}});

    unassignKitFromPatient = ({
        patientId,
        kits,
    }: {
        patientId: number;
        kits: IdsRequestParamDTO;
    }): Promise<SimpleMessageResponseDTO> => httpApi.delete({url: urls.PATIENT_INVENTORY(patientId), data: kits});
    unassignDeviceFromKit = ({
        kitId,
        data,
    }: {
        kitId: number;
        data: RemoveDeviceFromEquipmentKitRequestDTO;
    }): Promise<SimpleMessageResponseDTO> => httpApi.delete({url: urls.EQUIPMENT_KIT_DEVICES(kitId), data});

    getPostInstallationChecklist = (id: number): Promise<PostInstallationCheckListResponseDTO> =>
        httpApi.get({url: urls.POST_INSTALLATION_CHECKLIST(id)});

    getFieldsHistory = (kitId: number): Promise<EquipmentKitHistoryEntryResponseDTO[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_FIELDS_HISTORY(kitId)});
    getStatusHistory = (kitId: number): Promise<EquipmentKitStatusChangeResponseDTO[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_STATUS_HISTORY(kitId)});
    getDeviceUpdatesHistory = (kitId: number): Promise<DeviceUpdateResponseDTO[]> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_DEVICE_UPDATE_HISTORY(kitId)});
    getPostInstallCheckListFullSizeImage = (documentId: string): Promise<VisualDocumentationResponseDTO> =>
        httpApi.get({url: urls.EQUIPMENT_KIT_POST_INSTALLATION_CHECKLIST_FULL_SIZE_IMAGE(documentId)});

    getAssignedDevices = (kitId: number): Promise<DeviceResponseDTO[]> =>
        httpApi.get({
            url: `${urls.EQUIPMENT_KITS}/${kitId}/devices`,
        });

    getWifiInformation = (patientId: number): Promise<WifiNetworkResponse[]> =>
        httpApi.get({url: urls.GET_WIFI_INFORMATION(patientId)});
    getNonKitsDevices = (patientId: number): Promise<NonKitDeviceResponseDTO[]> =>
        httpApi.get({url: urls.NON_KIT_DEVICES(patientId)});
    updateNonKitsDevices = (patientId: number, data: NonKitDeviceRequestDTO[]): Promise<NonKitDeviceResponseDTO[]> =>
        httpApi.put({url: urls.NON_KIT_DEVICES(patientId), data});
    calculateEcylinderDuration = (data: CylinderDurationRequestDTO): Promise<CylinderDurationResponseDTO> =>
        httpApi.put({url: urls.CALCULATE_CYLINDER_DURATION, data});
    saveEcylinderDuration = (
        patientId: number,
        data: PatientCalculateEcylinderDurationRequestDTO
    ): Promise<CylinderDurationResponseDTO> => httpApi.put({url: urls.SAVE_PATIENT_CYLINDER_DURATION(patientId), data});
}

const kitService = new KitService();
export default kitService;
